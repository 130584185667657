h1{
    text-align: center;
}



main{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.yes {
    background-color: green;

}

.no{
    background-color: red;
}

p {
    font-size: .8rem;
    position: absolute;
    bottom: 5%;
}